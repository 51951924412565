.Badge {
    padding: 1px 6px;
    border-radius: 4px;
}

.box-background--green100{
    background-color: #cbf4c9;
}

.box-background--grey{
    background-color: #4f566b;
}

.Text-color--green600 {
    color: #0e6245;
    font-weight: 500;
    text-align: center;
}
.Text-color--grey {
    color: #e3e8ee;
    font-weight: 500;
    text-align: center;
}

.text-color--red600 {
    color: #a41c4e;
    font-weight: 500;
    text-align: center;
}

.box-background--red100 {
    background-color: #fde2dd;
}

/* '' */