.modall {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index:100000
  }
  
  .modal-main {
    position: fixed;
    background: white;
    width: 95%;
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
  }

  .modal-card {
    position: fixed;
    background: #ffff;
    width: 30%;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }

  .modal-open {
    overflow: visible;
  }
  
  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }

  .modal-dialog,
  .modal-content {
      /* 80% of window height */
      height: 100%;
  }
  
  .modal-body {
      /* 100% = dialog height, 120px = header + footer */
      max-height: calc(100vh - 210px);  /* calc(100% - 120px); */
      overflow-y: auto;
  }

  textarea {
    /* width: 300px; */
    font-size: 14px;
    height: 500px;
  }

  .order-form {
    display: flex;
    flex-direction: column;
  }