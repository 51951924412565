
/* .csv-download {
  text-align: right;
  margin-right: 3em;
} */



.csv-date-btn {
  margin-right: 3em;
}

.divider{
  width:5px;
  height:auto;
  display:inline-block;
}

/* .panel-body > .table{margin-bottom:0px;} */

.panel{
  -webkit-box-shadow: 0 0 10px hsla(0,0%,0%,.2),
  0 0 200px hsla(0,0%,100%,.75);
-moz-box-shadow: 0 0 10px hsla(0,0%,0%,.2),
  0 0 200px hsla(0,0%,100%,0.75);
box-shadow: 0 0 10px hsla(0,0%,0%,.2),
  0 0 200px hsla(0,0%,100%,.75);
}

.panel-body{
  margin-top:-2.5em;
}

.formHead {
  margin-bottom:-0.1em;

}


.main{
  margin-top:2em;
}


form {
  margin-top: 3em
}

.title {
  margin-left: 1em;
}

.containerr {
  /* border: 1px solid #7777; */
  /* max-height: 900px; */
  /* overflow-y: scroll; */
  width: 95%;
  margin-top:4em ;
  border-radius:2px;
/* -moz-border-radius:6px; */
  margin:4em auto;
  /* margin-right:5em; */
}

.btnGroup {
  display: inline-block;
  margin-left:65vh;
}

textarea {
  height:150vh;
  /* width:100%; */
  width: 100%;
  margin:0 auto auto -33vh;
  border-collapse:separate;
/* border:solid black 1px; */
border-radius:6px;
-moz-border-radius:6px;
font-size: 1em;
}

table {
  width:100%;
  background: #f6f6f6;
  font-size: 18px;
  /* margin-top:20vh; */
  margin: 0 auto;
  -webkit-box-shadow: 0 0 10px hsla(0,0%,0%,.2),
                      0 0 200px hsla(0,0%,100%,.75);
     -moz-box-shadow: 0 0 10px hsla(0,0%,0%,.2),
                      0 0 200px hsla(0,0%,100%,0.75);
          box-shadow: 0 0 10px hsla(0,0%,0%,.2),
                      0 0 200px hsla(0,0%,100%,.75);
text-align:left;
border-collapse:separate;
border:solid black 1px;
border-radius:6px;
-moz-border-radius:6px;
margin-top:2em;
}

thead{
  background-color: #f9f9f9;
  background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0,0%,0%,0)), to(hsla(0,0%,0%,.1)));
  background-image: -webkit-linear-gradient(top, hsla(0,0%,0%,0), hsla(0,0%,0%,.1));
  background-image:    -moz-linear-gradient(top, hsla(0,0%,0%,0), hsla(0,0%,0%,.1));
  background-image:     -ms-linear-gradient(top, hsla(0,0%,0%,0), hsla(0,0%,0%,.1));
  background-image:      -o-linear-gradient(top, hsla(0,0%,0%,0), hsla(0,0%,0%,.1));
  /* border: 1px solid #ccc; */
  /* border-bottom: 2px solid #ccc;
  font-weight: bold;
  text-shadow: 0 1px 1px hsla(0,0%,100%,.75); */
}

thead:first-child tr:first-child th:first-child, tbody:first-child tr:first-child td:first-child {
  border-radius: 4px 0 0 0;
}
thead:last-child tr:last-child th:first-child, tbody:last-child tr:last-child td:first-child {
  border-radius: 0 0 0 4px;
}

h2 {
     font-size: 29px;
    font-weight: lighter;
    display: inline-block;
    /* font-family:'Open Sans', sans-serif; */
    margin:0;
    margin-right: 42px;
}

.submitBtn{
  /* float:right; */
  position: absolute;
/* right:    1 ; */
margin-top:-2.31em;
margin-left: -4.75em;
margin-bottom: 2em; 
/* top:10; */
/* left:0  ; */
/* bottom:   0; */
}

button {
  /* position:absolute; */
  /* right:0; */
  /* float:right; */

  margin: 0 0.1vh;  
  /* margin-bottom: 0.5vh; */
  display:inline-block;

  font-size: 0.8em;
  /* font-family: sans-serif; */
  color:#333;
    text-shadow:1px 1px #fff;
    text-decoration: none;
    text-align:center;
    display:inline-block; 
    /* padding:0.5em 1.75em 0.5em 1em; */
    border-radius: 0.25em;
    background: #f8f8f8;
    border:1px solid #ccc;
  /* blue stripe */
    position: relative;
    /* margin:0 0 1px;  */
  margin:auto ;
}

  /* button .submitBtn {
  margin-top: 5vh;
  } */

.quitBtn{
  float: right;
  
}

thead tr{
  background-color: #F5F5F5;
}

/* tr:not(:first-child):hover {
  background-color: #F5F5F5;
} */

.notFirst:hover {
  background-color: #337AB7;
  color:white;
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(3);
  box-shadow: 0px 0px 5px rgba(0,0,0,0.3);
  -webkit-box-shadow: 0px 0px 5px rgba(0,0,0,0.3);
  -moz-box-shadow: 0px 0px 5px rgba(0,0,0,0.3);
}

.btn {
  font-size: 16px;
}


thead td {
  border: none;
  color: #444;
}


.column {
  /* width: 75%; */
  padding: 10px;
  float:right;
}

.right{
  width:75%;
  /* position:absolute; */

}


.row:after {
  content: "";
  display: table;
  clear: both;
}

#select{
  z-index: 100000;
}

.op-container-wrapper {
  display: flex;
  flex-direction: row;
}

.img-upload-wrapper{
  margin-left: 10rem;
}